import "./sections.css"

import React from "react"

import axios from "axios"

import { API_BASE_URL } from "../../constants/config"
import { defaultDog } from "../../constants/defaults"

const Section1 = () => {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/website/dogs/stories").then(({ data }) => {
                setData(data.data.data);
            })
        }
        return () => {
            m = false;
        }
    }, [])

    return <section className="success-stories-sec">
        <div className="content">

            {data && data.map((item, key) => {
                return <div key={key} className="item">
                    <div className="thumb">

                        <img src={item.featured_image ? API_BASE_URL.replace("/v1", "/") + item.featured_image.path : defaultDog} alt={item.title} />
                    </div>
                    <div className="text">
                        <h3>{item.title}</h3>
                        <div className="text-content site-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                </div>
            })}
        </div>
    </section>
}

const Sections = {
    Section1
}

export default Sections