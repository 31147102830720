import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/success-stories/sections"
import banner from "../images/success-stories-banner-bg.jpg"
const StoriesPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Success Stories" />
    <InnerBanner theme="success-story-banner white" image={banner} title="Success Stories" description={`If you have pictures of a DFWLRRC Lab and would like to submit a picture for this page, please email us with the picture and the dog’s name (and human’s if there are any). If your picture is not in a digital format, email the webmaster and we can make other arrangements.`} />

    <Sections.Section1 />
  </Layout>
}

export default StoriesPage